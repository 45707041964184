import { gql, useSubscription } from "@apollo/client";
import { useState } from "react";

export interface ParlansMessage {
    messageId: string;
    user: string;
    message: string;
    type: ParlansMessageTypes;
}

export type ParlansMessageTypes = 'TEXT' | 'STICKER' | 'LOTTIE';

export default function useChat(recipient: string) {
    const [messages, setMessage] = useState<ParlansMessage[]>([]);
    const { loading } = useSubscription(SUBSCRIPTION_GQL, {
        variables: {
            recipient
        },
        onSubscriptionData: (result) => {
            console.log('Error?: ', result.subscriptionData.error);
            const copy = [
                {
                    messageId: result.subscriptionData.data?.onChatMessage.messageId as string,
                    user: result.subscriptionData.data?.onChatMessage.userName as string,
                    message: result.subscriptionData.data?.onChatMessage.message as string,
                    type: result.subscriptionData.data?.onChatMessage.type as ParlansMessageTypes
                },
                ...messages
            ];
            if (copy.length > 500)
                copy.pop();
            setMessage(copy);
        }
    });

    return {
        loading,
        messages
    }
}

const SUBSCRIPTION_GQL = gql`
    subscription ($recipient: ID!) {
        onChatMessage(recipient: $recipient) {
            type
            message
            userName
            messageId
            recipient
        }
    }
`;

