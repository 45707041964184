import { gql, useApolloClient } from "@apollo/react-hooks";
import { 
    AudioInputControl,
    ContentShareControl,
    useMeetingManager,
    VideoInputControl,
    VideoTileGrid,
    ControlBarButton,
    Phone,
    VideoTile
} from "amazon-chime-sdk-component-library-react";
import { useEffect, useState } from "react";
import { useAttendeeNames } from "../../hooks/useAttendeeNames";
import { useAttendeesList } from "../../hooks/useAttendeesList";
import { EmptyMeetingScreen } from "./EmptyMeetingScreen";
import { VolumeButton } from "./VolumeButton";
import './sass/Meeting.scss';

export function Meeting() {
    useAttendeeNames();
    const [status, setStatus] = useState('LOADING');
    const apolloClient = useApolloClient();
    const meetingManager = useMeetingManager();
    const [map, subscribeToAttendees, unsubscribeToAttendees] = useAttendeesList();

    const loadMeeting = async () => {
        try {
            setStatus('LOADING');
            const result = await apolloClient.mutate({
                mutation: JOIN_MEETING,
                variables: {
                    meetingId: 'dev:ae2fe61d-a0f2-4e47-9559-56ac3575b619'
                }
            });

            const { data } = result;
            console.log(data);
            await meetingManager.join({
                attendeeInfo: {
                    AttendeeId: data.joinMeeting.attendee.attendeeId,
                    ExternalUserId: data.joinMeeting.attendee.userId,
                    JoinToken: data.joinMeeting.attendee.connectionToken,
                },
                meetingInfo: {
                    ExternalMeetingId: 'dev:ae2fe61d-a0f2-4e47-9559-56ac3575b619',
                    MediaPlacement: JSON.parse(data.joinMeeting.meeting.connectionData.MediaPlacement),
                    MediaRegion: data.joinMeeting.meeting.connectionData.MediaRegion,
                    MeetingId: result.data.joinMeeting.meeting.meetingId,
                },
            });

            subscribeToAttendees();
            meetingManager.start();
            setStatus('JOINED');
        } catch (error) {
            console.error(error);
            setStatus('INACTIVE');
        }
    }

    useEffect(
        () => {
            loadMeeting();
            return () => {
                unsubscribeToAttendees();
                meetingManager.leave();
            }
        },
        []
    );

    const endMeeting = async () => {
        setStatus('LOADING');
        unsubscribeToAttendees();
        await meetingManager.leave();
        setStatus('HANG_UP');
    }
    
    console.log(map, Object.keys(map), Object.values(map));
    const users = Object.values(map);

    return (
        <div className="components-meetings-meeting">
            <div className="video">
                <VideoTileGrid 
                    className="video-tile"
                    noRemoteVideoView={
                        users.length < 1 ? (
                            <EmptyMeetingScreen
                                status={status as any}
                                retryHandler={loadMeeting}
                            />
                        ) : (
                            <>
                                {
                                    users.map((attendee: any) => (
                                        <VideoTile 
                                            key={attendee.id}
                                            nameplate={attendee.id}
                                        />
                                    ))
                                }
                            </>
                        )                        
                    }
                >
                </VideoTileGrid>
            </div>
            <div className="controls">
                {
                    status === 'JOINED' ? (
                        <>
                            <AudioInputControl />
                            <ContentShareControl />
                            <VideoInputControl />
                            <VolumeButton />
                            {/* End button */}
                            <ControlBarButton
                                icon={<Phone />}
                                label="End"
                                onClick={endMeeting}
                            />
                        </>
                    ) : null
                }
            </div>
        </div>
    );
}

const JOIN_MEETING = gql`
    mutation joinMeeting($meetingId: ID!) {
        joinMeeting(meetingId: $meetingId) {
            meeting {
                meetingId
                serviceMeetingId
                connectionData {
                    MediaPlacement
                    MediaRegion
                }
            }
            attendee {
                attendeeId
                userId
                connectionToken
            }
        }
    }
`;