import { Fragment, useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Swal from 'sweetalert2';
import AuthContext from '../../contexts/AuthContext';
import { verifyCode, sendCode, forgotPassword as forPassword, submitPassword } from '../../hooks/useAuth'

interface AuthChildFormProps { toggleMode: () => void, referrer?: string }

export default function SignIn({ toggleMode, referrer }: AuthChildFormProps) {
    const context: any = useContext(AuthContext);

    //state
    const [forgotPassword, setForgotPassword] = useState(false);
    const [codePassword, setCodePassword] = useState(false);
    const [verificationCode, setVerificationCode] = useState({
        code: false,
        display: "", 
        email: ''
    });

    //handlers
    const handlerForgotPassword = (e: any) => {
        setForgotPassword(!forgotPassword);
    }
    const handlerSendCode = async () => {
        const result: any = await sendCode(verificationCode.email)
        
        if (result?.error) {
            Swal.fire({
                icon: 'error',
                showConfirmButton: false, 
                heightAuto: false,
                text: result.message,
                timer: 2500
            })
        } else{
            Swal.fire({
                icon: 'success',
                showConfirmButton: false, 
                heightAuto: false,
                text: result.message,
                timer: 2500
            })
        }
        
    }
    const handlerVerifyCode = async (code: string) => {
        const result: any = await verifyCode(verificationCode.email, code)

        if (result?.error) {
            Swal.fire({
                icon: 'error',
                showConfirmButton: false, 
                heightAuto: false,
                text: result.message,
                timer: 2500
            })
        } else {
            Swal.fire({
                icon: 'success',
                showConfirmButton: false, 
                heightAuto: false,
                text: "Cuenta verificada con exito, vuelva a iniciar sesion",
                timer: 2500
            })

            setCodePassword(true);
                    setForgotPassword(false);
                    
                    setVerificationCode({
                        ...verificationCode,
                        code: false,
                        display: ""
                    });
        }
        
    }

    return (
        <Fragment>
            {
                verificationCode.code ? (
                    <Formik
                        initialValues={{ code: ''}}
                        validate={values => {
                            const errors: any = {};
                            if (!values.code) errors.code = 'Required';
                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            await handlerVerifyCode(values.code)
                            setSubmitting(false);
                            
                        }
                    } > 
                    {({ isSubmitting }) => (
                            <Form className="component-form-auth">
                                <h1>Veryfication Code</h1>
                                <Field type="text" name="code" placeholder="Code" />
                                <ErrorMessage name="code" component="div" />
                                <button type="submit" disabled={isSubmitting}>
                                    Verify
                                </button>
                                <button type="submit" onClick={handlerSendCode}>
                                    Send 
                                </button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    null
                )
            }
            {
                !forgotPassword ? 
                (
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => {
                        const errors: any = {};
                        if (!values.email) errors.email = 'Required';
                        if (!values.password) errors.password = 'Required';
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const result =  await context.signIn(values, referrer);
                        console.log(result);
                        
                        // FIXME: Esto hay que refactorizarlo.
                        if(!result.code) {
                        }
                        if(result.code === 'UserNotConfirmedException') {
                            setVerificationCode({
                                display: "none",
                                code: true,
                                email: values.email
                            });
                            setForgotPassword(true);
                            setCodePassword(false);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                showConfirmButton: false, 
                                heightAuto: false,
                                text: result.message,
                                timer: 2500
                            })
                        }
                        setSubmitting(false);
                    }
                } >
                    {({ isSubmitting }) => (
                        <Form className="component-form-auth">
                            <h1>Hello!</h1>
                            <p>Sign in to your account</p>
                            <Field type="email" name="email" placeholder="Email" />
                            <ErrorMessage name="email" component="div" />
                            <Field type="password" name="password" placeholder="Password" />
                            <ErrorMessage name="password" component="div" />
                            <button type="submit" disabled={isSubmitting}>
                                SIGN IN
                            </button>
                            <p>Don't have an account?</p>
                            <button onClick={toggleMode} type="button" >
                                Create
                            </button>
                            <button onClick={handlerForgotPassword} type="button">
                                Forgot password?
                            </button>
                        </Form>
                    )}
                </Formik>
                ) : (
                    // FIXME: Sacar en componentes aparte, esto está horrible.
                    codePassword ?
                    (
                        <Formik 
                        initialValues={{ email: '', code: "", password: '', newPassword: ''}}
                        validate={values => {
                            const errors: any = {};
                            if (!values.email) errors.email = 'Required';
                            if (!values.code) errors.code = 'Required';
                            if (!values.password) errors.password = 'Required';
                            if (!values.newPassword) errors.newPassword = 'Required';
                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            console.log(values);
                            if(values.password !== values.newPassword)
                                throw new Error("Password diferentes")

                            
                            await submitPassword(values.email, values.code, values.newPassword);
                            Swal.fire({
                                title: "Password restart",
                                text: `Contraseña restablecida con exito`,
                                showConfirmButton: false,
                                timer: 2500,
                                icon: 'success'
                            }); 
                            setCodePassword(false);
                            toggleMode();
                            //TODO: validar que el email exista
            
                            setSubmitting(false);
                        }
                    } > 
                    {({ isSubmitting }) => (
                            <Form className="component-form-auth">
                                <h1>Reset your password</h1>
                                <Field type="number" name="code" placeholder="Code" />
                                <ErrorMessage name="code" component="div" />
                                <Field type="email" name="email" placeholder="Email" />
                                <ErrorMessage name="email" component="div" />
                                <Field type="password" name="password" placeholder="New Password" />
                                <ErrorMessage name="password" component="div" />
                                <Field type="password" name="newPassword" placeholder="Re Password" />
                                <ErrorMessage name="newPassword" component="div" />
                                <button type="submit" disabled={isSubmitting}>
                                    Reset Password
                                </button>
                                <button onClick={handlerForgotPassword} type="button">
                                    Login
                                </button>
            
                            </Form>
                        )}
                    </Formik>
                    ) : (
                        <div style = {{"display": verificationCode.display}}>
                        <Formik
                        initialValues={{ email: ''}}
                        validate={values => {
                            const errors: any = {};
                            if (!values.email) errors.email = 'Required';
                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            await forPassword(values.email);
                            Swal.fire({
                                title: "Code sent",
                                text: `Se ha enviado un codigo al email: ${values.email}`,
                                showConfirmButton: false,
                                timer: 2500,
                                icon: 'success'
                            });
                            setCodePassword(true);
                            
                            //TODO: validar que el email exista
            
                            setSubmitting(false);
                        }
                    } > 
                    {({ isSubmitting }) => (
                            <Form className="component-form-auth">
                                <h1>Reset your password</h1>
                                <Field type="email" name="email" placeholder="Email" />
                                <ErrorMessage name="email" component="div" />
                                <button type="submit" disabled={isSubmitting}>
                                    Send
                                </button>
                            </Form>
                        )}
                    </Formik>
                    </div>
                    )
                
                )
            }
        
        </Fragment>
    )

}