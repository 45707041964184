import CategoryCard from "../../components/cards/CategoryCard";
import TopNavigation from "../../components/navigation/TopNavigation";
import './sass/ConferenceMode.scss';

const viewerImage = require('../../assets/img/viewer.jpg').default;
const meetingImage = require('../../assets/img/meeting.jpg').default;

export function ConferenceMode() {
    return (
        <div className="app-home">
            <div className="categories">
                <TopNavigation />
                <p className="categories-title">
                    Choose a conference mode!
                </p>
                <div className="modes">
                    <CategoryCard
                        image={viewerImage}
                        name="Conferencist"
                        route="/conferences/meeting/dev:ae2fe61d-a0f2-4e47-9559-56ac3575b619"
                    />
                    <CategoryCard
                        image={meetingImage}
                        name="Viewer"
                        route="/conferences/stream/dev:ae2fe61d-a0f2-4e47-9559-56ac3575b619" 
                    />
                </div>
            </div>
        </div> 
    );
}