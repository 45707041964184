import AuthForms from '../auth/AuthForms'
import './sass/AuthScreen.scss'
const businessStill = require('../../assets/img/stills-business.png');

export default function AuthScreen(props: any) {
    console.log('Referer ', props.location.state?.from);
    return(
        <div className="routes-auth">
            <div className="content">
                <div className="form">
                    <AuthForms referrer={props.location.state?.from} /> 
                </div>
                <div className="presentation">
                    <img src={businessStill.default} alt="" /> 
                </div>
            </div>
        </div>
    )
}