export const ApplicationConfig = {
    endpoint: 'http://localhost:3000/dev',
    awsConfig: {
        Auth: {
            userPoolId: 'us-east-2_nb0GdgSUT',
            region: 'us-east-2',
            userPoolWebClientId: '768055gp8mlrop80kqvdoltiju'
        },
        Api: {
            region: 'us-east-2',
            graphqlEndpoint: 'https://5h75o4pqgjezfng4ej7qbikjjq.appsync-api.us-east-2.amazonaws.com/graphql',
            authType: 'AMAZON_COGNITO_USER_POOLS'
        }
    },
    ivsLib: 'https://player.live-video.net/1.4.0/amazon-ivs-player.min.js'
};