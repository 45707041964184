import { Formik, Form, Field, ErrorMessage } from 'formik';
import { signUp } from '../../hooks/useAuth';
import Swal from 'sweetalert2'

interface AuthChildFormProps { toggleMode: () => void }

export default function SignUp({ toggleMode }: AuthChildFormProps) {
    return (
        <Formik
            initialValues={{ name: '', lastname: '', email: '', password: '' }}
            validate={values => {
                const errors: any = {};
                if (!values.name) errors.name = 'Required';
                if (!values.lastname) errors.lastname = 'Required';
                if (!values.email) errors.email = 'Required';
                if (!values.password) errors.password = 'Required';
                return errors;
            }}

            onSubmit={async (values, { setSubmitting }) => {
                const result: any = await signUp(values);


                if (!result.success) {
                    console.log(JSON.stringify(result))
                    Swal.fire({
                        icon: 'error',
                        title: "Error de registro!",
                        text: result.message,
                        showConfirmButton: false,
                        heightAuto: false,
                        timer: 2500,
                        timerProgressBar: true,
                        allowOutsideClick: false
                    });
                    // TODO: controlar mejor los errores
                    // (El mensaje de error viene directo de AWS, hay que traducirlo/ponerlo de mejor forma)
                    return;
                }

                Swal.fire({
                    icon: 'success',
                    title: "Cuenta creada con exito",
                    text: `Se ha enviado un código de verificación a ${values.email}`,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2500,
                    timerProgressBar: true,
                    allowOutsideClick: false
                });

                toggleMode();
                setSubmitting(false);
            }} >

            {({ isSubmitting }) => (
                <Form className="component-form-auth">
                    <h1>Hello, friend</h1>
                    <div className="inline-inputs">
                        <div className="field-error-group">
                            <Field type="text" name="name" placeholder="Name"/>
                            <ErrorMessage name="name" component="div" />
                        </div>
                        <div className="field-error-group">
                            <Field type="text" name="lastname" placeholder="Last Name"/>
                            <ErrorMessage name="lastname" component="div" />
                        </div>
                    </div>
                    <div>
                        <Field type="email" name="email" placeholder="Email"/>
                        <ErrorMessage name="email" component="div" />
                    </div>
                    <div>
                        <Field type="password" name="password" placeholder="Password"/>
                        <ErrorMessage name="password" component="div" />
                    </div>
                    <button type="submit" disabled={isSubmitting}>
                        CREATE ACCOUNT
                    </button>
                    <p>Already have an account?</p>
                    <button onClick={toggleMode} type="button">
                        Sign in
                    </button>
                </Form>
            )}
        </Formik>
    )
}