import { useState } from "react";
import SignUp from '../../components/forms/SignUp'
import SignIn from '../../components/forms/SignIn'
import "./sass/AuthForms.scss"

interface AuthFormsProps { referrer?: string }

export default function AuthForms(props: AuthFormsProps) {
    const [isSignUp, setIsSignUp] = useState(false);
    const toggleMode = () => setIsSignUp(!isSignUp);

    if (isSignUp)
        return <SignUp toggleMode={toggleMode} />

    return <SignIn referrer={props.referrer} toggleMode={toggleMode} />
}