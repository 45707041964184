import { lightTheme, MeetingProvider } from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { Meeting } from "../../components/meetings/Meeting";
import Chat from "../../components/ui/Chat";
import './sass/Conference.scss';

export function Conference() {
    return (
        <ThemeProvider theme={lightTheme}>
            <MeetingProvider>
                <div className="app-conference">
                    <Meeting />
                    <Chat />
                </div>
            </MeetingProvider>
        </ThemeProvider>
    );
}