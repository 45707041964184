import { gql, useApolloClient } from "@apollo/react-hooks";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useEffect } from "react";

export const useAttendeeNames = () => {
    const apolloClient = useApolloClient();
    const meetingManager = useMeetingManager();

    useEffect(
        () => {
            meetingManager.getAttendee = async (id, externalId) => {
                try {
                    console.log('Attendee id: ', id, 'Parlans id: ', externalId);
                    const response = await apolloClient.query(
                        {
                            query: USER_NAME_QUERY,
                            variables: {
                                userId: externalId
                            }
                        }
                    )
                    console.log('Retrieve user errors: ', response.error);
                    const user = response.data?.user;

                    return {
                        name: `${user.name} ${user.lastname}` 
                    };
                } catch (err) {
                    return {
                        name: undefined
                    };
                }
            }
        },
        [meetingManager]
    );
}

const USER_NAME_QUERY = gql`
    query UserName($userId: ID!) {
        user(id: $userId) {
            name
            lastname
        }
    }
`;