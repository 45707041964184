import LottieElement from "../cards/LottieElement";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import './sass/StickerMenu.scss';
import { useMemo } from "react";

interface ChatProps {
    show: Boolean;
    sendMessage: SendMessageHandler;
}
type SendMessageHandler = (message: string, type: any) => void;

export default function StickerMenu({ show, sendMessage }: ChatProps) {
    // Es importante usar memorización para evitar calcular las keys en cada render.
    const headers = useMemo(
        () => Object.keys(stickers) as (keyof StickersObj)[], 
        []
    );

    return (
        <Tabs
            className={"message-stickers " + (show ? "" : "hidden")}
            defaultIndex={0}
            onSelect={index => console.log(index)}
            selectedTabPanelClassName="is-selected"
            selectedTabClassName="is-selected"
        >
            <TabList className="message-sticker-tabs">
                {
                    headers.map((header, i) => <Tab key={`sticker-tab-${i}`} value={String(i)}>{header}</Tab>)
                }
            </TabList>
            {
                // Esto se va a modificar despues
                headers.map((header, i) => (
                    <TabPanel className="message-stickers" key={`stickers-panel-${i}`}>
                        <StickerPanel
                            header={header}
                            sendMessage={sendMessage}
                        />
                    </TabPanel>
                ))
            }
        </Tabs>
    );
}

interface StickerPanelProps {
    header: keyof StickersObj; 
    sendMessage:SendMessageHandler;
}

function StickerPanel({ header, sendMessage }: StickerPanelProps) {
    return (
        <>
            {
                stickers[header].map((sticker, key) => (
                    <div key={`sticker-${header}-${key}`}>
                       {
                           (sticker.type === 'ANIMATED') ? (
                               <span
                                   className="sticker"
                                   onClick={() => sendMessage(sticker.file, 'ANIMATED')}
                               >
                                   <LottieElement
                                       path={sticker.file}
                                       autoplay={false}
                                   />
                               </span>
                           ) : (
                               <img
                                   className="sticker"
                                   src={sticker.file}
                                   alt="sticker"
                                   onClick={() => sendMessage(sticker.file, 'STICKER')}
                               />
                           )
                       }
                    </div>
                ))
            }
        </>
    );
}

type StickersObj = typeof stickers;
const stickers = {
    "Gatos": [
        { type: "STICKER", file: "/img/stickers/01.webp" },
        { type: "STICKER", file: "/img/stickers/02.webp" },
        { type: "STICKER", file: "/img/stickers/03.webp" },
        { type: "STICKER", file: "/img/stickers/04.webp" },
        { type: "STICKER", file: "/img/stickers/05.webp" },
        { type: "STICKER", file: "/img/stickers/06.webp" },
        { type: "STICKER", file: "/img/stickers/07.webp" },
        { type: "STICKER", file: "/img/stickers/08.webp" },
        { type: "STICKER", file: "/img/stickers/09.webp" },
        { type: "STICKER", file: "/img/stickers/10.webp" },
        { type: "STICKER", file: "/img/stickers/11.webp" },
        { type: "STICKER", file: "/img/stickers/12.webp" },
        { type: "STICKER", file: "/img/stickers/13.webp" },
        { type: "STICKER", file: "/img/stickers/14.webp" },
        { type: "STICKER", file: "/img/stickers/15.webp" },
        { type: "STICKER", file: "/img/stickers/16.webp" }
    ],
    "Cereza": [
        { type:"ANIMATED", file: "/img/lottie/0.json" },
        { type:"ANIMATED", file: "/img/lottie/1.json" },
        { type:"ANIMATED", file: "/img/lottie/2.json" },
        { type:"ANIMATED", file: "/img/lottie/3.json" },
        { type:"ANIMATED", file: "/img/lottie/4.json" },
        { type:"ANIMATED", file: "/img/lottie/5.json" },
        { type:"ANIMATED", file: "/img/lottie/6.json" },
        { type:"ANIMATED", file: "/img/lottie/7.json" },
        { type:"ANIMATED", file: "/img/lottie/8.json" },
        { type:"ANIMATED", file: "/img/lottie/9.json" },
        { type:"ANIMATED", file: "/img/lottie/10.json" },
        { type:"ANIMATED", file: "/img/lottie/11.json" },
        { type:"ANIMATED", file: "/img/lottie/12.json" },
        { type:"ANIMATED", file: "/img/lottie/13.json" },
        { type:"ANIMATED", file: "/img/lottie/14.json" },
        { type:"ANIMATED", file: "/img/lottie/15.json" },
        // TODO: Revisar como mejorar el rendimiento de esto, maybe cargandolos gradualmente¿?
        // { type:"ANIMATED", file: "/img/lottie/16.json" },
        // { type:"ANIMATED", file: "/img/lottie/17.json" },
        // { type:"ANIMATED", file: "/img/lottie/18.json" },
        // { type:"ANIMATED", file: "/img/lottie/19.json" },
        // { type:"ANIMATED", file: "/img/lottie/20.json" },
        // { type:"ANIMATED", file: "/img/lottie/21.json" },
        // { type:"ANIMATED", file: "/img/lottie/22.json" },
        // { type:"ANIMATED", file: "/img/lottie/23.json" },
        // { type:"ANIMATED", file: "/img/lottie/24.json" },
        // { type:"ANIMATED", file: "/img/lottie/25.json" },
        // { type:"ANIMATED", file: "/img/lottie/26.json" },
        // { type:"ANIMATED", file: "/img/lottie/27.json" },
        // { type:"ANIMATED", file: "/img/lottie/28.json" },
        // { type:"ANIMATED", file: "/img/lottie/29.json" },
        // { type:"ANIMATED", file: "/img/lottie/30.json" }
    ],
    "Kurzgesagt": [
        { type: "STICKER", file: "/img/stickers/ks/0.webp" },
        { type: "STICKER", file: "/img/stickers/ks/1.webp" },
        { type: "STICKER", file: "/img/stickers/ks/2.webp" },
        { type: "STICKER", file: "/img/stickers/ks/3.webp" },
        { type: "STICKER", file: "/img/stickers/ks/4.webp" },
        { type: "STICKER", file: "/img/stickers/ks/5.webp" },
        { type: "STICKER", file: "/img/stickers/ks/6.webp" },
        { type: "STICKER", file: "/img/stickers/ks/7.webp" },
        { type: "STICKER", file: "/img/stickers/ks/8.webp" },
        { type: "STICKER", file: "/img/stickers/ks/9.webp" },
        { type: "STICKER", file: "/img/stickers/ks/10.webp" },
        { type: "STICKER", file: "/img/stickers/ks/11.webp" },
        { type: "STICKER", file: "/img/stickers/ks/12.webp" },
        { type: "STICKER", file: "/img/stickers/ks/13.webp" },
        { type: "STICKER", file: "/img/stickers/ks/14.webp" },
        { type: "STICKER", file: "/img/stickers/ks/15.webp" },
        { type: "STICKER", file: "/img/stickers/ks/16.webp" },
        { type: "STICKER", file: "/img/stickers/ks/17.webp" },
        { type: "STICKER", file: "/img/stickers/ks/18.webp" },
        { type: "STICKER", file: "/img/stickers/ks/19.webp" },
        { type: "STICKER", file: "/img/stickers/ks/20.webp" },
        { type: "STICKER", file: "/img/stickers/ks/21.webp" },
        { type: "STICKER", file: "/img/stickers/ks/22.webp" },
        { type: "STICKER", file: "/img/stickers/ks/23.webp" }
    ]
}
